var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-actions',{attrs:{"action-collapse":"","title":"Blog Form"}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('div',{staticClass:"border rounded p-2"},[_c('h4',{staticClass:"mb-1"},[_vm._v(" Image ")]),_c('b-media',{staticClass:"flex-column flex-md-row",attrs:{"no-body":"","vertical-align":"center"}},[_c('b-media-aside',[_c('b-img',{ref:"refPreviewEl",staticClass:"rounded mr-2 mb-1 mb-md-0",attrs:{"id":"profile","src":_vm.input.avatar_pathurl,"height":"110","width":"170"}})],1),_c('b-media-body',[_c('small',{staticClass:"text-muted"},[_vm._v("(Recommend Size : 200x200)")]),_c('b-card-text',{staticClass:"my-50"},[_c('b-link',{attrs:{"id":"blog-image-text"}},[_vm._v(" C:\\fakepath\\"+_vm._s(_vm.input.userFile ? _vm.input.userFile.name : 'banner.jpg')+" ")])],1),_c('div',{staticClass:"d-inline-block"},[_c('b-form-file',{ref:"refInputEl",attrs:{"accept":".jpg, .png, .gif","placeholder":"Choose file"},on:{"input":_vm.inputImageRenderer},model:{value:(_vm.input.userFile),callback:function ($$v) {_vm.$set(_vm.input, "userFile", $$v)},expression:"input.userFile"}})],1)],1)],1)],1)]),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Title")]),_c('validation-provider',{attrs:{"rules":"required","name":"title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Name"},model:{value:(_vm.input.title),callback:function ($$v) {_vm.$set(_vm.input, "title", $$v)},expression:"input.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',[_vm._v("uri")]),_c('validation-provider',{attrs:{"rules":"required","name":"uri"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"uri"},model:{value:(_vm.input.uri),callback:function ($$v) {_vm.$set(_vm.input, "uri", $$v)},expression:"input.uri"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"subdetail"}},[_vm._v("SubDetail")]),_c('validation-provider',{attrs:{"rules":"min:0","name":"subdetail"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"subdetail","placeholder":"subdetail","rows":"3"},model:{value:(_vm.input.shortdesc),callback:function ($$v) {_vm.$set(_vm.input, "shortdesc", $$v)},expression:"input.shortdesc"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"subdetail"}},[_vm._v("Description")]),_c('validation-provider',{attrs:{"rules":"min:0","name":"description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quill-editor',{attrs:{"id":"description"},model:{value:(_vm.input.description),callback:function ($$v) {_vm.$set(_vm.input, "description", $$v)},expression:"input.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"subdetail"}},[_vm._v("Category")]),_c('validation-provider',{attrs:{"rules":"required","name":"category_id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"name","options":_vm.categories},model:{value:(_vm.input.category_id),callback:function ($$v) {_vm.$set(_vm.input, "category_id", $$v)},expression:"input.category_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('label',[_vm._v("Highlight")]),_c('validation-provider',{attrs:{"rules":"required","name":"is_highlight"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{staticClass:"custom-control-primary",attrs:{"checked":"true","name":"is_highlight","switch":""},model:{value:(_vm.input.is_highlight),callback:function ($$v) {_vm.$set(_vm.input, "is_highlight", $$v)},expression:"input.is_highlight"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('label',[_vm._v("Active")]),_c('validation-provider',{attrs:{"rules":"required","name":"is_active"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{staticClass:"custom-control-primary",attrs:{"checked":"true","name":"is_active","switch":""},model:{value:(_vm.input.is_active),callback:function ($$v) {_vm.$set(_vm.input, "is_active", $$v)},expression:"input.is_active"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_vm._v(" Submit ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }