<template>
    <b-card-actions action-collapse title="Blog Form">
       <validation-observer ref="simpleRules">
        <b-form>
            <b-row>
               <b-col
          cols="12"
          class="mb-2"
        >
          <div class="border rounded p-2">
            <h4 class="mb-1">
              Image
            </h4>
            <b-media
              no-body
              vertical-align="center"
              class="flex-column flex-md-row"
            >
              <b-media-aside>
                <b-img
                 id="profile"
                  ref="refPreviewEl"
                  :src="input.avatar_pathurl"
                  height="110"
                  width="170"
                  class="rounded mr-2 mb-1 mb-md-0"
                />
              </b-media-aside>
              <b-media-body>

                <small class="text-muted">(Recommend Size : 200x200)</small>
                <b-card-text class="my-50">
                  <b-link id="blog-image-text">
                    C:\fakepath\{{ input.userFile ? input.userFile.name : 'banner.jpg' }}
                  </b-link>
                </b-card-text>
                <div class="d-inline-block">
                  <b-form-file
                    ref="refInputEl"
                    v-model="input.userFile"
                    accept=".jpg, .png, .gif"
                    placeholder="Choose file"
                    @input="inputImageRenderer"
                  />
                </div>
              </b-media-body>
            </b-media>
          </div>
        </b-col>
                <b-col md="12">
                    <b-form-group>
                        <label>Title</label>
                        <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="title"
                        >
                            <b-form-input
                                v-model="input.title"
                                placeholder="Name"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col md="12">
                    <b-form-group>
                        <label>uri</label>
                        <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="uri"
                        >
                            <b-form-input
                                v-model="input.uri"
                                placeholder="uri"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col md="12">
                    <b-form-group>
                        <label for="subdetail">SubDetail</label>
                         <validation-provider
                        #default="{ errors }"
                        rules="min:0"
                        name="subdetail"
                        >
                           <b-form-textarea
                            id="subdetail"
                            v-model="input.shortdesc"
                            placeholder="subdetail"
                            rows="3"
                        />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        
                    </b-form-group>
                </b-col>
                <b-col md="12">
                    <b-form-group>
                        <label for="subdetail">Description</label>
                         <validation-provider
                        #default="{ errors }"
                        rules="min:0"
                        name="description"
                        >
                        <quill-editor
                            id="description"
                            v-model="input.description"
                          
                         
                            />
                          
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        
                    </b-form-group>
                </b-col>
                 <b-col md="6">
                    <b-form-group>
                        <label for="subdetail">Category</label>
                       <validation-provider
                            #default="{ errors }"
                            rules="required"
                            name="category_id"
                            >
                            <v-select
                                            v-model="input.category_id"
                                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                            label="name"
                                            :options="categories"
                                    />
                                <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                 <b-col md="3">
                    <b-form-group>
                    <label>Highlight</label>
                     <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="is_highlight"
                        >
                             <b-form-checkbox
                                checked="true"
                                v-model="input.is_highlight"
                                class="custom-control-primary"
                                name="is_highlight"
                                switch
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                  
                  </b-form-group>  
                </b-col>
                 <b-col md="3">
                    <b-form-group>
                    <label>Active</label>
                     <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="is_active"
                        >
                             <b-form-checkbox
                                checked="true"
                                v-model="input.is_active"
                                class="custom-control-primary"
                                name="is_active"
                                switch
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                  
                  </b-form-group>  
                </b-col>
                <b-col cols="12">
                  <b-button
                    variant="primary"
                    type="submit"
                    @click.prevent="validationForm"
                  >
                    Submit
                  </b-button>
                </b-col>
            </b-row>
        </b-form>
        </validation-observer>
    </b-card-actions>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BFormInput,BFormGroup , BForm, BRow, BCol, BButton,BFormTextarea,BFormCheckbox } from 'bootstrap-vue'
import {
  BCard, BMedia, BAvatar, BCardText, BMediaAside, BMediaBody, BImg, BFormFile, BLink
} from 'bootstrap-vue'
import BCardActions from '../../../@core/components/b-card-actions/BCardActions.vue'
import {
  required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length,
} from '@validations'
import axios from '@axios'


//popup
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'

import router from '@/router'



// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'
export default{
    components:{
        BCardActions,
        ValidationProvider,
        ValidationObserver,
        BForm,
        BFormGroup,
        BRow,
        BCol,
        BFormInput,
        BFormTextarea,
        BFormCheckbox,
        BButton,
        vSelect,
        BCard, BMedia, BAvatar, BCardText, BMediaAside, BMediaBody, BImg, BFormFile, BLink,
        quillEditor

    },
    directives: {
        Ripple,
    },
    data(){
        return {
            id:router.currentRoute.params.id,
             userData: JSON.parse(localStorage.getItem('userData')),
            input:{
                title:null,
                uri:null,
                shortdesc:null,
                description:null,
                category_id:null,
                is_active:true,
                is_highlight:false,
                userFile:null

            },
            categories:[]
           
        }
    },
    setup() {
        const refInputEl = ref(null)
        const refPreviewEl = ref(null)

        const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => { refPreviewEl.value.src = base64 })

        return {
        refInputEl,
        refPreviewEl,
        inputImageRenderer,
        }
    },
    created() {
        this.getCatgory();
         if(this.id != undefined){
          setTimeout(() => {
                this.getData();
            }, 500);
       
      }
    },
    methods: {

        getCatgory(){
            axios.post('knowledge/v1/category/getall')
            .then(res => { 
                console.log('res',res);
                this.categories = res.data.data; 
            })

        },
         async getData(){
                var blog = await axios.get('knowledge/v1/detail/'+this.id);
                
                if(!blog.data.data.id){
                    this.$router.push('/knowledgebase/lists', () => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                        title: `ERror`,
                        icon: 'CoffeeIcon',
                        variant: 'danger',
                        text: `Data not Found.`,
                        },
                    })
                });
                }else{
                    let blg = blog.data.data;
                    this.input = blg;
                    if(blg.category_id){
                        var types = this.categories.filter(j => j.id == blg.category_id);
                        if(types){
                            this.input.category_id =types[0];
                        }
                    }
                    
                    this.input.is_active =  blg.is_active==1?true:false;
                    this.input.is_highlight =  blg.is_highlight==1?true:false;
                    this.input.avatar_pathurl = blg.fullfilepath;
                    this.input.filename = blg.filename;

                  
                }
        },
        makeToast(variant = null,title,message) {
            this.$bvToast.toast(message, {
                title: title,
                variant,
                solid: true,
            })
        },
        validationForm() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                
                   this.input.base64 = '';
                  if(this.input.userFile){
                       this.input.base64 = document.getElementById('profile').src;
                  }
                  this.input.created_by = this.userData.id;
                  console.log('input',  this.input);
                  var data = JSON.stringify(this.input);
                   console.log('input', this.input);
                    if(this.id != undefined){
                        axios.put('knowledge/v1/update/'+this.id,data)
                        .then(res => { 
                        
                            if(!res.data.success){
                            this.makeToast('danger','Error 403',res.data.message);
                            
                            
                            }else{
                            this.$router.push('/knowledgebase/lists', () => {
                                this.$toast({
                                    component: ToastificationContent,
                                    position: 'top-right',
                                    props: {
                                    title: `Done`,
                                    icon: 'CoffeeIcon',
                                    variant: 'success',
                                    text: `You have successfully created.`,
                                    },
                                })
                            });
                            

                            }
                        // this.actions = res.data.data; 
                        }).catch(error => {
                            this.makeToast('danger','Error 403',error);
                            console.log('error',error);
                        })
                    }else{
                        axios.post('knowledge/v1/add',data)
                        .then(res => { 
                        
                            if(!res.data.success){
                            this.makeToast('danger','Error 403',res.data.message);
                            
                            
                            }else{
                            this.$router.push('/knowledgebase/lists', () => {
                                this.$toast({
                                    component: ToastificationContent,
                                    position: 'top-right',
                                    props: {
                                    title: `Done`,
                                    icon: 'CoffeeIcon',
                                    variant: 'success',
                                    text: `You have successfully created.`,
                                    },
                                })
                            });
                            

                            }
                        // this.actions = res.data.data; 
                        }).catch(error => {
                            this.makeToast('danger','Error 403',error);
                            console.log('error',error);
                        })

                    }
                }
            })
        },
    }
    
}
</script>